<template>
  <div class="min-h-screen mevcut-container">
    <div class="relative mt-5" style="min-height: 50px">
      <div
        class="mevcut-title absolute whitespace-nowrap left-1/2 -translate-x-1/2"
      >
        <span>{{ t('stores') }}</span>
      </div>
    </div>
    <div class="border-b border-text_color mx-auto mb-3 max-w-md">
      <RikazSearchField
        :value="storeName"
        class="ltr:mr-3.5 rtl:ml-3.5"
        :placeholder="t('enter_search_text')"
        :input-class="'w-full '"
        :search-size-icon="20"
        prefix
        close-class="text-mevcut-light w-5 h-5 cursor-pointer"
        @search-term="filterStoreName"
      />
    </div>

    <StoresGrid
      class="mb-5"
      :enable-cache="false"
      :fetch-params="{ orderBy: 'id' ,store_type_pattern: 1 }"
      :with-query-params="true"
    />
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()
useServerSeoMeta(
  useOgMeta(
    url + '/stores',
    t('stores'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)
function filterStoreName(value: string) {
  storeName.value = value
  useQueryNavigation({ name: value })
}
const route = useRoute()
const storeName = ref(route.query.name as string)
useHead({
  title: t('stores'),
  link: [useSeoCanonical(url + '/stores')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('stores'), url + '/stores')
    ])
  ]
})
</script>
